import { Suspense } from 'react';

import dayjs from 'dayjs';
import Duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isToday from 'dayjs/plugin/isToday';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { Route, Routes } from 'react-router-dom';

import { lazyRetry } from '@cast/utils';

import { IS_DEV } from 'common/constants';
import { ContactsFormDialog, MultiStepContactsForm } from 'components/dialogs';
import { SuspenseLoader } from 'components/loaders';
import { WhenAuthenticated } from 'components/router';
import { Experiment, Variant } from 'core/experiments';
import { flags } from 'core/flags';
import { TourProvider } from 'core/tour';
import { ForbiddenPage } from 'pages/error/Forbidden';

import { LandingRedirect } from './LandingRedirect';
import { MainLayout } from './main-layout';

dayjs.extend(isBetween);
dayjs.extend(isToday);
dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(Duration);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(timezone);

const DashboardController = lazyRetry(
  () => import('pages/dashboard/DashboardController')
);

const ClusterController = lazyRetry(
  () => import('pages/cluster/ClusterController')
);

const OrganizationController = lazyRetry(
  () => import('pages/organization/OrganizationController')
);

const ApiAccessKeysController = lazyRetry(
  () => import('pages/api-access-keys/ApiAccessKeysController')
);

const SettingsController = lazyRetry(
  () => import('pages/settings/SettingsController')
);

const NotificationsController = lazyRetry(
  () => import('pages/notifications/NotificationsController')
);

const YamlPreviewPage = lazyRetry(
  () => import('pages/yaml-preview/YamlPreviewPage')
);

const CommitmentsController = lazyRetry(
  () => import('pages/commitments/CommitmentsController')
);

const LLMController = lazyRetry(
  () => import('pages/llm-optimization/LLMController')
);

const DBOptimizationController = lazyRetry(
  () => import('pages/db-optimization/DbOptimizationController')
);

export const App = () => {
  return (
    <TourProvider>
      <Routes>
        <Route
          path="*"
          element={
            <MainLayout>
              <Suspense fallback={<SuspenseLoader />}>
                <Routes>
                  <Route index Component={LandingRedirect} />

                  <Route
                    path="/dashboard/*"
                    element={
                      <WhenAuthenticated>
                        <DashboardController />
                      </WhenAuthenticated>
                    }
                  />

                  <Route
                    path="/settings/*"
                    element={
                      <WhenAuthenticated>
                        <SettingsController />
                      </WhenAuthenticated>
                    }
                  />

                  <Route
                    path="/notifications/*"
                    element={
                      <WhenAuthenticated
                        flag={flags['page-modules'].notifications.value}
                      >
                        <NotificationsController />
                      </WhenAuthenticated>
                    }
                  />

                  <Route
                    path="/user/api-access/*"
                    element={
                      <WhenAuthenticated>
                        <ApiAccessKeysController />
                      </WhenAuthenticated>
                    }
                  />

                  <Route
                    path="/external-clusters/:clusterId/*"
                    element={
                      <WhenAuthenticated>
                        <ClusterController />
                      </WhenAuthenticated>
                    }
                  />

                  <Route
                    path="/organization/*"
                    element={
                      <WhenAuthenticated
                        flag={
                          flags['page-modules']['organization-management'].value
                        }
                      >
                        <OrganizationController />
                      </WhenAuthenticated>
                    }
                  />

                  <Route
                    path="/yaml-preview"
                    element={
                      <WhenAuthenticated>
                        <YamlPreviewPage />
                      </WhenAuthenticated>
                    }
                  />

                  <Route
                    path="/commitments/*"
                    element={
                      <WhenAuthenticated>
                        <CommitmentsController />
                      </WhenAuthenticated>
                    }
                  />

                  <Route
                    path="/llm/*"
                    element={
                      <WhenAuthenticated
                        flag={
                          flags['page-modules']['organization-llm'][
                            'cost-report'
                          ].value
                        }
                      >
                        <LLMController />
                      </WhenAuthenticated>
                    }
                  />
                  <Route
                    path="/dbo/*"
                    element={
                      <WhenAuthenticated
                        flag={flags['page-modules'].organization.dbo.value}
                      >
                        <DBOptimizationController />
                      </WhenAuthenticated>
                    }
                  />
                </Routes>
                {!IS_DEV && (
                  <Experiment name="signup-form">
                    <Variant id="0">
                      <ContactsFormDialog />
                    </Variant>
                    <Variant id="1">
                      <MultiStepContactsForm />
                    </Variant>
                  </Experiment>
                )}
              </Suspense>
            </MainLayout>
          }
        />
        <Route
          path="/forbidden"
          element={
            <MainLayout noMenu sidebar={false}>
              <ForbiddenPage />
            </MainLayout>
          }
        />
      </Routes>
    </TourProvider>
  );
};
