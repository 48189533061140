import {
  AuditNode,
  ComputedNodeStatus,
  Node,
  NodeIssue,
  NodePricing,
  NodeResourceOffering,
  NodeResponse,
  NodeStatus,
} from '@cast/types';

import { isNodeFailedToDrain } from '../cluster';

export const isFallbackNode = (node: NodeResponse | AuditNode) => {
  return (
    ('isSpotFallback' in node && node.isSpotFallback) ||
    Boolean(node.labels?.['scheduling.cast.ai/spot-fallback'])
  );
};

export const getPricingType = (node: NodeResponse): NodeResourceOffering => {
  if (isFallbackNode(node)) {
    return NodeResourceOffering.FALLBACK;
  }
  if (node.spotConfig?.isSpot) {
    return NodeResourceOffering.SPOT;
  }
  return NodeResourceOffering.ON_DEMAND;
};

export const getNodeIssues = (node: NodeResponse): NodeIssue[] => {
  const nodeIssues: NodeIssue[] = [];
  if (isNodeFailedToDrain(node)) {
    nodeIssues.push('failedToDrain');
  }
  return nodeIssues;
};

export const enhanceNode = (
  node: NodeResponse,
  pricingInfos?: NodePricing[]
): Node => {
  const pricingInfo = pricingInfos?.find((p) => p.id === node.id);
  return {
    ...node,
    state: {
      phase:
        node.unschedulable && node.state?.phase && node.state.phase === 'ready'
          ? ComputedNodeStatus.CORDONED
          : (node.state?.phase as NodeStatus | undefined),
    },
    resourceOffering: getPricingType(node),
    regionLabel:
      node.labels?.['topology.kubernetes.io/region'] ||
      node.labels?.['failure-domain.beta.kubernetes.io/region'],
    unremovable:
      node.labels?.['autoscaling.cast.ai/removal-disabled'] === 'true',
    castNodeId: node.labels?.['provisioner.cast.ai/node-id'],
    issues: getNodeIssues(node),
    pricingInfo,
  };
};
