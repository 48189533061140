import { OrganizationRole } from '@cast/types';

import { Feature, PermissionType, RootFeature } from './types';

export const config: Record<
  OrganizationRole,
  Record<RootFeature, Record<PermissionType, boolean>>
> = {
  [OrganizationRole.owner]: {
    [Feature.ORGANIZATION]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: true,
      [PermissionType.EDIT]: true,
      [PermissionType.DELETE]: true,
    },
    [Feature.BILLING]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: true,
      [PermissionType.EDIT]: true,
      [PermissionType.DELETE]: true,
    },
    [Feature.CLUSTER]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: true,
      [PermissionType.EDIT]: true,
      [PermissionType.DELETE]: true,
    },
    [Feature.CLUSTER__COST_REPORT]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: true,
      [PermissionType.EDIT]: true,
      [PermissionType.DELETE]: true,
    },
    [Feature.CREDENTIALS]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: true,
      [PermissionType.EDIT]: true,
      [PermissionType.DELETE]: true,
    },
    [Feature.ACCESS_KEYS]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: true,
      [PermissionType.EDIT]: true,
      [PermissionType.DELETE]: true,
    },
    [Feature.REPORTS]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: true,
      [PermissionType.EDIT]: true,
      [PermissionType.DELETE]: true,
    },
    [Feature.NOTIFICATIONS]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: true,
      [PermissionType.EDIT]: true,
      [PermissionType.DELETE]: true,
    },
    [Feature.SCHEDULED_REBALANCING]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: true,
      [PermissionType.EDIT]: true,
      [PermissionType.DELETE]: true,
    },
    [Feature.COMMITMENTS]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: true,
      [PermissionType.EDIT]: true,
      [PermissionType.DELETE]: true,
    },
    [Feature.WORKLOAD_OPTIMIZATION]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: true,
      [PermissionType.EDIT]: true,
      [PermissionType.DELETE]: true,
    },
    [Feature.ORGANIZATION_SECURITY]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: true,
      [PermissionType.EDIT]: true,
      [PermissionType.DELETE]: true,
    },
    [Feature.ORGANIZATION_COST_REPORT]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: true,
      [PermissionType.EDIT]: true,
      [PermissionType.DELETE]: true,
    },
    [Feature.LLM_OPTIMIZER]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: true,
      [PermissionType.EDIT]: true,
      [PermissionType.DELETE]: true,
    },
    [Feature.DB_OPTIMIZATION]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: true,
      [PermissionType.EDIT]: true,
      [PermissionType.DELETE]: true,
    },
  },
  [OrganizationRole.member]: {
    [Feature.ORGANIZATION]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: false,
      [PermissionType.EDIT]: false,
      [PermissionType.DELETE]: false,
    },
    [Feature.BILLING]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: false,
      [PermissionType.EDIT]: false,
      [PermissionType.DELETE]: false,
    },
    [Feature.CLUSTER]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: true,
      [PermissionType.EDIT]: true,
      [PermissionType.DELETE]: true,
    },
    [Feature.CLUSTER__COST_REPORT]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: true,
      [PermissionType.EDIT]: true,
      [PermissionType.DELETE]: true,
    },
    [Feature.CREDENTIALS]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: true,
      [PermissionType.EDIT]: true,
      [PermissionType.DELETE]: true,
    },
    [Feature.ACCESS_KEYS]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: true,
      [PermissionType.EDIT]: true,
      [PermissionType.DELETE]: true,
    },
    [Feature.REPORTS]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: true,
      [PermissionType.EDIT]: true,
      [PermissionType.DELETE]: true,
    },
    [Feature.NOTIFICATIONS]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: true,
      [PermissionType.EDIT]: true,
      [PermissionType.DELETE]: true,
    },
    [Feature.SCHEDULED_REBALANCING]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: true,
      [PermissionType.EDIT]: true,
      [PermissionType.DELETE]: true,
    },
    [Feature.COMMITMENTS]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: true,
      [PermissionType.EDIT]: true,
      [PermissionType.DELETE]: true,
    },
    [Feature.WORKLOAD_OPTIMIZATION]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: true,
      [PermissionType.EDIT]: true,
      [PermissionType.DELETE]: true,
    },
    [Feature.ORGANIZATION_SECURITY]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: true,
      [PermissionType.EDIT]: true,
      [PermissionType.DELETE]: true,
    },
    [Feature.ORGANIZATION_COST_REPORT]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: true,
      [PermissionType.EDIT]: true,
      [PermissionType.DELETE]: true,
    },
    [Feature.LLM_OPTIMIZER]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: true,
      [PermissionType.EDIT]: true,
      [PermissionType.DELETE]: true,
    },
    [Feature.DB_OPTIMIZATION]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: true,
      [PermissionType.EDIT]: true,
      [PermissionType.DELETE]: true,
    },
  },
  [OrganizationRole.analyst]: {
    [Feature.ORGANIZATION]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: false,
      [PermissionType.EDIT]: false,
      [PermissionType.DELETE]: false,
    },
    [Feature.BILLING]: {
      [PermissionType.VIEW]: false,
      [PermissionType.CREATE]: false,
      [PermissionType.EDIT]: false,
      [PermissionType.DELETE]: false,
    },
    [Feature.CLUSTER]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: false,
      [PermissionType.EDIT]: false,
      [PermissionType.DELETE]: false,
    },
    [Feature.CLUSTER__COST_REPORT]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: true,
      [PermissionType.EDIT]: true,
      [PermissionType.DELETE]: true,
    },
    [Feature.CREDENTIALS]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: false,
      [PermissionType.EDIT]: false,
      [PermissionType.DELETE]: false,
    },
    [Feature.ACCESS_KEYS]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: false,
      [PermissionType.EDIT]: false,
      [PermissionType.DELETE]: false,
    },
    [Feature.REPORTS]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: false,
      [PermissionType.EDIT]: false,
      [PermissionType.DELETE]: false,
    },
    [Feature.NOTIFICATIONS]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: false,
      [PermissionType.EDIT]: false,
      [PermissionType.DELETE]: false,
    },
    [Feature.SCHEDULED_REBALANCING]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: false,
      [PermissionType.EDIT]: false,
      [PermissionType.DELETE]: false,
    },
    [Feature.COMMITMENTS]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: false,
      [PermissionType.EDIT]: false,
      [PermissionType.DELETE]: false,
    },
    [Feature.WORKLOAD_OPTIMIZATION]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: false,
      [PermissionType.EDIT]: false,
      [PermissionType.DELETE]: false,
    },
    [Feature.ORGANIZATION_SECURITY]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: false,
      [PermissionType.EDIT]: false,
      [PermissionType.DELETE]: false,
    },
    [Feature.ORGANIZATION_COST_REPORT]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: true,
      [PermissionType.EDIT]: true,
      [PermissionType.DELETE]: true,
    },
    [Feature.LLM_OPTIMIZER]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: false,
      [PermissionType.EDIT]: false,
      [PermissionType.DELETE]: false,
    },
    [Feature.DB_OPTIMIZATION]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: false,
      [PermissionType.EDIT]: false,
      [PermissionType.DELETE]: false,
    },
  },
  [OrganizationRole.viewer]: {
    [Feature.ORGANIZATION]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: false,
      [PermissionType.EDIT]: false,
      [PermissionType.DELETE]: false,
    },
    [Feature.BILLING]: {
      [PermissionType.VIEW]: false,
      [PermissionType.CREATE]: false,
      [PermissionType.EDIT]: false,
      [PermissionType.DELETE]: false,
    },
    [Feature.CLUSTER]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: false,
      [PermissionType.EDIT]: false,
      [PermissionType.DELETE]: false,
    },
    [Feature.CLUSTER__COST_REPORT]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: false,
      [PermissionType.EDIT]: false,
      [PermissionType.DELETE]: false,
    },
    [Feature.CREDENTIALS]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: false,
      [PermissionType.EDIT]: false,
      [PermissionType.DELETE]: false,
    },
    [Feature.ACCESS_KEYS]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: false,
      [PermissionType.EDIT]: false,
      [PermissionType.DELETE]: false,
    },
    [Feature.REPORTS]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: false,
      [PermissionType.EDIT]: false,
      [PermissionType.DELETE]: false,
    },
    [Feature.NOTIFICATIONS]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: false,
      [PermissionType.EDIT]: false,
      [PermissionType.DELETE]: false,
    },
    [Feature.SCHEDULED_REBALANCING]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: false,
      [PermissionType.EDIT]: false,
      [PermissionType.DELETE]: false,
    },
    [Feature.COMMITMENTS]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: false,
      [PermissionType.EDIT]: false,
      [PermissionType.DELETE]: false,
    },
    [Feature.WORKLOAD_OPTIMIZATION]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: false,
      [PermissionType.EDIT]: false,
      [PermissionType.DELETE]: false,
    },
    [Feature.ORGANIZATION_SECURITY]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: false,
      [PermissionType.EDIT]: false,
      [PermissionType.DELETE]: false,
    },
    [Feature.ORGANIZATION_COST_REPORT]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: false,
      [PermissionType.EDIT]: false,
      [PermissionType.DELETE]: false,
    },
    [Feature.LLM_OPTIMIZER]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: false,
      [PermissionType.EDIT]: false,
      [PermissionType.DELETE]: false,
    },
    [Feature.DB_OPTIMIZATION]: {
      [PermissionType.VIEW]: true,
      [PermissionType.CREATE]: false,
      [PermissionType.EDIT]: false,
      [PermissionType.DELETE]: false,
    },
  },
};
