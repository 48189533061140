import { DateRange } from 'react-day-picker';

export enum DateRangeFilter {
  ThisMonth = 'THIS_MONTH',
  Last15Mins = 'LAST_15_MINS',
  Last30Mins = 'LAST_30_MINS',
  Last24Hours = 'LAST_24_HOURS',
  LastHour = 'LAST_HOUR',
  TODAY = 'TODAY',
  Last7Days = 'LAST_7_DAYS',
  Last30Days = 'LAST_30_DAYS',
  Last1Year = 'LAST_YEAR',
  LastMonth = 'LAST_MONTH',
  LastWeek = 'LAST_WEEK',
  Last2Weeks = 'LAST_2_WEEKS',
}

type DateArgs = { fromNow?: boolean };

export type Filter = {
  label?: string;
  valueLabel?: string;
  date: (args?: DateArgs) => { from: Date; to: Date };
};

export type Filters = { [key in DateRangeFilter]: Filter };

export type DatePeriod = { from: Date | undefined; to: Date | undefined };

type BaseProviderProps = {
  inputValue: () => string;
  popoverAnchor: HTMLInputElement | null;
  setPopoverAnchor: (element: HTMLInputElement | null) => void;
  handleOpen: (event: React.MouseEvent<HTMLInputElement>) => void;
  handleClose: () => void;
  handleApply: () => void;
  format: string;
  selectedFilterPreset?: DateRangeFilter;
  setSelectedFilterPreset?: (filter?: DateRangeFilter) => void;
  allowTimeInput?: boolean;
};

type SingleDayPickerProps = BaseProviderProps & {
  mode: 'single';
  selected: Date | undefined;
  setSelected: (day: Date) => void;
  toDate?: Date;
  fromDate?: Date;
};

type PeriodComparisonPickerProps = BaseProviderProps & {
  mode: 'single';
  selected: DatePeriod | undefined;
  setSelected: (day: DatePeriod) => void;
  periods: number[];
  activePeriod: number;
  setActivePeriod: (period: number) => void;
  toDate?: Date;
  fromDate?: Date;
};

type MultiDayPickerProps = BaseProviderProps & {
  mode: 'multiple';
  selected: Date[] | undefined;
  setSelected: (days: Date[]) => void;
  toDate?: Date;
  fromDate?: Date;
};

type RangeDatePickerProps = BaseProviderProps & {
  mode: 'range';
  selected: DateRange | undefined;
  setSelected: (range?: DateRange) => void;
  filterPresets?: DateRangeFilter[];
  maxNumberOfDays?: number;
  toDate?: Date | ((dateRange?: DateRange) => Date | undefined);
  fromDate?: Date | ((dateRange?: DateRange) => Date | undefined);
};

export type DatePickerProviderProps =
  | SingleDayPickerProps
  | PeriodComparisonPickerProps
  | MultiDayPickerProps
  | RangeDatePickerProps;

export const isRangeDatePicker = (
  context: DatePickerProviderProps
): context is RangeDatePickerProps => {
  return (context as RangeDatePickerProps).mode === 'range';
};

export const isMultiDatePicker = (
  context: DatePickerProviderProps
): context is MultiDayPickerProps => {
  return (context as MultiDayPickerProps).mode === 'multiple';
};

export const isPeriodComparisonPicker = (
  context: DatePickerProviderProps
): context is PeriodComparisonPickerProps => {
  return (
    (context as PeriodComparisonPickerProps).mode === 'single' &&
    Boolean((context as PeriodComparisonPickerProps).periods?.length)
  );
};

export const isSingleDatePicker = (
  context: DatePickerProviderProps
): context is SingleDayPickerProps => {
  return (
    (context as SingleDayPickerProps).mode === 'single' &&
    !isPeriodComparisonPicker(context)
  );
};
