import { useContext, useEffect } from 'react';

import Typography from '@mui/material/Typography';
import { sub } from 'date-fns';
import differenceInMonths from 'date-fns/differenceInMonths';

import { List } from '../../lists/List';
import { ListItem } from '../../lists/ListItem';
import { ListItemText } from '../../lists/ListItemText';
import { ListSubheader } from '../../lists/ListSubheader';
import { DatePickerContext } from '../DatePickerProvider';
import { allFilters } from '../filters';
import { DateRangeFilter, Filters } from '../types';
import { isSelectedFilterPreset } from '../utils';

type Props = {
  filters: Partial<Filters>;
  setMonth: (date: Date | undefined) => void;
};

const FilterPresetsList = ({ filters, setMonth }: Props) => {
  const ctx = useContext(DatePickerContext);

  const updateCalendarMonthView = (from: Date, to: Date) => {
    let _month: Date;
    if (differenceInMonths(to, from) >= 2) {
      _month = sub(to, {
        months: 1,
      });
    } else {
      _month = from;
    }
    setMonth(_month);
  };

  useEffect(() => {
    if (ctx.selectedFilterPreset) {
      const { date } = allFilters[ctx.selectedFilterPreset];
      const { from, to } = date();

      updateCalendarMonthView(from, to);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctx.selectedFilterPreset]);

  if (ctx.mode !== 'range') {
    return null;
  }

  return (
    <List size="small" disablePadding testId="date-picker-filter-presets">
      <ListSubheader
        sx={{ alignItems: 'center', padding: '0 8px', color: 'grey.900' }}
      >
        <Typography variant="P12M">Range:</Typography>
      </ListSubheader>

      {Object.entries(filters)?.map(([preset, filter]) => {
        return filter ? (
          <ListItem
            key={filter.label}
            sx={{ borderRadius: '4px' }}
            selected={
              ctx.selected
                ? isSelectedFilterPreset(ctx.selected, filter)
                : false
            }
            onClick={() => {
              ctx.setSelected(filter.date());
              ctx.setSelectedFilterPreset?.(preset as DateRangeFilter);
            }}
            component="li"
            testId={'filter-preset-' + preset}
          >
            <ListItemText primary={filter.label} />
          </ListItem>
        ) : null;
      })}
    </List>
  );
};

export default FilterPresetsList;
