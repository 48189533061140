import { Box, SxProps } from '@mui/material';
import { useField } from 'react-final-form';

import { mergeSx } from '@cast/design-system';

import { RffSelect } from 'core/forms/rff';

import { OptionalTag } from './OptionalTag';
import { useGetSelectChipSx } from '../_hooks/useGetSelectChipSx';

type Props = {
  index: number;
  sx?: SxProps;
};

const options = [
  {
    name: 'Deployment',
    value: 'Deployment',
  },
  {
    name: 'DaemonSet',
    value: 'DaemonSet',
  },
  {
    name: 'StatefulSet',
    value: 'StatefulSet',
  },
  {
    name: 'CronJob',
    value: 'CronJob',
  },
  {
    name: 'Job',
    value: 'Job',
  },
  {
    name: 'Pod',
    value: 'Pod',
  },
  {
    name: 'Node',
    value: 'Node',
  },
];

export const KindSelect = ({ index, sx }: Props) => {
  const { input } = useField(`groups[${index}].kinds`);
  const value: string[] = input.value || [];
  const {
    input: { value: selectedClusterIds },
  } = useField(`groups[${index}].clusters`);

  const chipSx = useGetSelectChipSx();

  return (
    <RffSelect
      testId={`kind-select-${index}`}
      name={`groups[${index}].kinds`}
      sx={mergeSx(sx, chipSx)}
      inputProps={{
        label: (
          <Box display="flex">
            Kind
            <OptionalTag />
          </Box>
        ),
        success: false, // Don't show green border
      }}
      inputText={!value.length ? 'Select' : 'Selected'}
      options={options}
      disabled={!selectedClusterIds?.length}
      optionLabel="name"
      optionValue="value"
      showClearAll
      multiple
      checkable
    />
  );
};
