import Big from 'big.js';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import flatMap from 'lodash/flatMap';
import flatten from 'lodash/flatten';
import groupBy from 'lodash/groupBy';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import meanBy from 'lodash/meanBy';
import pick from 'lodash/pick';
import random from 'lodash/random';
import size from 'lodash/size';
import sumBy from 'lodash/sumBy';
import takeRight from 'lodash/takeRight';
import uniqBy from 'lodash/uniqBy';

import {
  BestPracticeCheckDetails,
  CostReportClusterResourceUsageResponse,
  NodeResourceOffering,
  NodeResponse,
  NodeSelectionListResponse,
  RebalancingAccepted,
  RebalancingNode,
  RebalancingNodeManagedByEnum,
  RebalancingPlan,
  RightsizingSummaryResponse,
  UpdateSelectionTemplateResponse,
  Workload,
  WorkloadCostReportResponse,
  WorkloadEfficiencyListResponseRaw,
  WorkloadLabelNamesResponse,
  WorkloadLabelValuesResponse,
  WorkloadsCostReportFilters,
  WorkloadTrafficDestination,
  WorkloadTrafficDestinationsHistory,
  SecurityInsightsResourceDetailsResponse,
  NamespaceReportResponse,
  NamespaceCostItem,
  CostReportWorkloadSummariesResponse,
  SecurityInsightsCheckResourcesResponse,
  ScalingPoliciesListResponse,
  CostReportAllocationGroupSummariesResponse,
  ClusterNodesPricingResponse,
} from '@cast/types';

import {
  getImageDetails,
  getImageDigests,
  getImageVulnerabilityDetails,
} from '../generators/security-insights';
import {
  advancedEvictorConfigModifier,
  allocationGroupComputeCostTotalCostsModifier,
  allocationGroupComputeCostWorkloadsModifier,
  allocationGroupNetworkCostSummariesModifier,
  allocationGroupNetworkCostWorkloadsModifier,
  allocationGroupsModifier,
  clusterDetailsModifier,
  clustersSummaryModifier,
  clusterSummaryModifier,
  costReportModifier,
  dailyCostsModifier,
  estimatedSavingsHistoryModifier,
  estimatedSavingsModifier,
  nodeConstraintsModifier,
  nodeModifier,
  organizationClustersReportModifier,
  policiesModifier,
  rebalancingPlanModifier,
  rebalancingWorkloadModifier,
  resourceUsageModifier,
  runtimeSecurityAnomaliesModifier,
  runtimeSecurityAnomalyDetailsModifier,
  runtimeSecurityRulesModifier,
  securityInsightsAttackPathDetailsModifier,
  securityInsightsAttackPathsModifier,
  securityInsightsBestPracticesDetailsModifier,
  securityInsightsBestPracticesFiltersModifier,
  securityInsightsBestPracticesModifier,
  securityInsightsImageDetailsModifier,
  securityInsightsImageDigestsModifier,
  securityInsightsImagePackagesModifier,
  securityInsightsImageResourcesModifier,
  securityInsightsImagesModifier,
  securityInsightsImageVulnerabilitiesModifier,
  securityInsightsImageVulnerabilityDetailsModifier,
  securityInsightsResourceDetailsModifier,
  securityInsightsIntegrationsModifier,
  securityInsightsBestPracticeOverviewModifier,
  woopWorkloadModifier,
  woopWorkloadsModifier,
  workloadComputeCostModifier,
  workloadEfficiencyModifier,
  workloadNetworkCostModifier,
  workloadTrafficDestinationsHistoryModifier,
  costReportWorkloadSummariesModifier,
  securityInsightsBestPracticesResourcesModifier,
  securityInsightsImageSecurityOverviewModifier,
  securityInsightsAttackPathsOverviewModifier,
  runtimeSecurityAnomaliesOverviewModifier,
  allocationGroupsEfficiencyModifier,
  dataTransferCostModifier,
  securityInsightsClustersSettingsModifier,
  securityInsightsWorkloadsModifier,
  runtimeSecurityRuleDetailsModifier,
  allocationGroupWorkloadsEfficiencyModifier,
  runtimeSecurityAnomalyEventsModifier,
  workloadGpuUtilizationModifier,
  workloadGpuWasteCostImpactModifier,
  workloadsGpuUtilizationModifier,
  clusterAnomaliesModifier,
  namespacesTotalCostsModifier,
  namespacesCostsSummaryModifier,
  namespacesNetworkTotalCostsModifier,
  namespacesNetworkCostsSummaryModifier,
  unscheduledPodsModifier,
  allocationGroupComputeCostTimedSummariesModifier,
} from '../modifiers';
import {
  getNodeResourceOffering,
  getRebalancingConfigurationTotal,
  getRebalancingGreenNodes,
  replaceClusterIdTokens,
  replaceRebalancingPlanIdTokens,
  uuidv4,
} from '../utils';

dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);

export const demoClusterDetails = async (orgId: string) => {
  const json = await import('../generated/demo/eks-cluster.json');
  return clusterDetailsModifier(
    JSON.stringify(json),
    {},
    { name: 'CAST AI demo cluster', orgId }
  );
};

export const demoClusterList = async (orgId: string) => {
  const json = await demoClusterDetails(orgId);
  return {
    items: [json],
  };
};

export const demoDailyCost = async () => {
  const json = await import('../generated/demo/cost-daily.json');
  return dailyCostsModifier(JSON.stringify(json), {}, {});
};

export const demoOrganizationClustersReport = async () => {
  const json = await import(
    '../generated/demo/organization-clusters-report.json'
  );
  return organizationClustersReportModifier(JSON.stringify(json), {}, {});
};

export const demoCostReport = async () => {
  const json = await import('../generated/demo/cost-report.json');
  return costReportModifier(JSON.stringify(json), {});
};

export const demoOrganizationClustersSummary = async () => {
  const json = await import('../generated/demo/clusters-summary.json');
  return clustersSummaryModifier(JSON.stringify(json), {});
};

export const demoOrganizationClusterSummary = async () => {
  const json = await import('../generated/demo/cluster-summary.json');
  return clusterSummaryModifier(JSON.stringify(json), {});
};

export const demoOrganizationClusterUnscheduledPods = async () => {
  const json = await import('../generated/demo/unscheduled-pods.json');
  return unscheduledPodsModifier(JSON.stringify(json), {});
};

export const demoResourceUsage = async (
  startTime: string,
  endTime: string,
  step: number
) => {
  let json: CostReportClusterResourceUsageResponse;
  switch (step) {
    case 600:
      json = await import('../generated/demo/resource-usage-minutes.json');
      break;
    case 3600:
      json = await import('../generated/demo/resource-usage-hours.json');
      break;
    case 86400:
    default:
      json = await import('../generated/demo/resource-usage-days.json');
      break;
  }
  const startDate = dayjs(startTime);
  const endDate = dayjs(endTime);
  const diffInSeconds = endDate.diff(startDate, 'second');
  const newItems = takeRight(json.items, Math.floor(diffInSeconds / step));
  return resourceUsageModifier(
    JSON.stringify({ ...json, items: newItems }),
    {},
    endDate
  );
};

export const demoWorkloadDataTransferCost = async (startTime: string) => {
  const json = await import('../generated/demo/data-transfer-cost.json');
  const startDate = dayjs(startTime);
  return workloadNetworkCostModifier(
    JSON.stringify(json),
    {},
    undefined,
    startDate
  );
};

export const demoReportCapabilities = async () => {
  const json = await import('../generated/demo/report-capabilities.json');

  return json;
};

export const demoWorkloadsGpuUtilizationSummary = async () => {
  const json = await import(
    '../generated/demo/workloads-gpu-utilization-summary.json'
  );

  return workloadsGpuUtilizationModifier(JSON.stringify(json));
};

export const demoWorkloadsGpuWasteCostImpact = async (
  startTime: string,
  endTime: string
) => {
  const json = await import(
    '../generated/demo/workloads-gpu-waste-cost-impact.json'
  );

  return workloadGpuWasteCostImpactModifier(
    JSON.stringify(json),
    startTime,
    endTime
  );
};

export const demoWorkloadGpuUtilizationSummary = async (
  startTime?: string,
  endTime?: string
) => {
  const json = await import(
    '../generated/demo/workload-gpu-utilization-summary.json'
  );

  return workloadGpuUtilizationModifier(
    JSON.stringify(json),
    startTime,
    endTime,
    undefined
  );
};

export const demoDataTransferCost = async (
  startTime: string,
  endTime: string
) => {
  const json = await import('../generated/demo/data-transfer-cost.json');
  return dataTransferCostModifier(JSON.stringify(json), {}, startTime, endTime);
};

export const demoWorkloadTrafficDestinations = async () => {
  const json = await import(
    '../generated/demo/workload-traffic-destinations.json'
  );
  return json;
};

export const demoWorkloadTrafficDestinationsHistory = async (
  startTime: string,
  endTime: string
) => {
  const json = await import(
    '../generated/demo/workload-traffic-destinations-history.json'
  );
  const response = json as WorkloadTrafficDestinationsHistory;

  const modified = workloadTrafficDestinationsHistoryModifier(
    JSON.stringify(response),
    {},
    dayjs(endTime)
  );

  return {
    destinations: modified.destinations?.map((item) => {
      const history = item.history?.filter(({ timestamp }) => {
        const today = dayjs();
        return (
          dayjs(timestamp).isSameOrBefore(today.endOf('day').add(1, 'ms')) &&
          dayjs(timestamp).isBetween(startTime, endTime, null, '[]')
        );
      });
      return {
        ...item,
        history,
        destination: history?.reduce((acc, item) => {
          return {
            ...acc,
            egress: {
              interZoneBytes: Big(item.egress?.interZoneBytes || 0)
                .add(acc.egress?.interZoneBytes || 0)
                .toFixed(6),
              interZoneCost: Big(item.egress?.interZoneCost || 0)
                .add(acc.egress?.interZoneCost || 0)
                .toFixed(6),
            },
            ingress: {
              interZoneBytes: Big(item.ingress?.interZoneBytes || 0)
                .add(acc.ingress?.interZoneBytes || 0)
                .toFixed(6),
              interZoneCost: Big(item.ingress?.interZoneCost || 0)
                .add(acc.ingress?.interZoneCost || 0)
                .toFixed(6),
            },
          };
        }, item.destination as WorkloadTrafficDestination),
      };
    }),
  };
};

export const demoWorkloadEfficiency = async (startTime: string) => {
  const json = await import('../generated/demo/workload-efficiency.json');
  const response = json as unknown as WorkloadEfficiencyListResponseRaw;
  const items: WorkloadCostReportResponse['items'] = response.items;

  return workloadEfficiencyModifier(
    JSON.stringify({
      clusterId: response.clusterId,
      topItems: response.topItems,
      items,
      metricsServerAvailable: response.metricsServerAvailable,
    }),
    {},
    response.clusterId,
    dayjs(startTime)
  );
};

export const demoWorkloadCostReport = async (
  start: string,
  end: string,
  filter?: WorkloadsCostReportFilters
): Promise<WorkloadCostReportResponse> => {
  const json = await import('../generated/demo/workload-cost-report.json');
  const response = json as WorkloadCostReportResponse;
  let items: WorkloadCostReportResponse['items'] = response.items;

  if (filter?.workloadNames) {
    items = response.items?.filter((w) =>
      filter.workloadNames?.includes(w.workloadName!)
    );
  }

  if (filter?.labels) {
    items = response.items?.filter(() => random(0, 1, false));
  }

  const parsedData = workloadComputeCostModifier(
    JSON.stringify({
      clusterId: response.clusterId,
      items,
    }),
    {}
  );

  return {
    clusterId: parsedData.clusterId,
    items: parsedData.items?.map((d) => {
      return {
        ...d,
        costMetrics: d.costMetrics?.filter(({ timestamp }) => {
          return (
            dayjs(timestamp).isAfter(start) &&
            dayjs(timestamp).isSameOrBefore(end)
          );
        }),
      };
    }),
  };
};

export const demoWorkloadSummaries = async (
  start: string,
  end: string,
  filter?: WorkloadsCostReportFilters,
  pageLimit?: string
): Promise<CostReportWorkloadSummariesResponse> => {
  const fixture = await demoWorkloadCostReport(start, end, filter);

  const items =
    fixture.items?.map((item) => {
      const costOnDemand = Big(
        item.costMetrics?.reduce((total, item) => {
          const cost = Big(item.uptimeMinutesOnDemand || 0)
            .div(60)
            .mul(item.costOnDemand || 0);

          return cost.add(total).toNumber();
        }, 0) || 0
      );

      const costSpot = Big(
        item.costMetrics?.reduce((total, item) => {
          const cost = Big(item.uptimeMinutesSpot || 0)
            .div(60)
            .mul(item.costSpot || 0);

          return cost.add(total).toNumber();
        }, 0) || 0
      );

      const costSpotFallback = Big(
        item.costMetrics?.reduce((total, item) => {
          const cost = Big(item.uptimeMinutesSpotFallback || 0)
            .div(60)
            .mul(item.costSpotFallback || 0);

          return cost.add(total).toNumber();
        }, 0) || 0
      );

      const cpuCount = Big(
        item.costMetrics?.reduce(
          (total, item) =>
            Big(item.cpuCountSpot || 0)
              .add(item.cpuCountOnDemand || 0)
              .add(item.cpuCostSpotFallback || 0)
              .add(total)
              .toNumber(),
          0
        ) || 0
      ).div(item.costMetrics?.length || 0);
      const ramGib = Big(
        item.costMetrics?.reduce(
          (total, item) =>
            Big(item.ramGibSpot || 0)
              .add(item.ramGibOnDemand || 0)
              .add(item.ramGibSpotFallback || 0)
              .add(total)
              .toNumber(),
          0
        ) || 0
      ).div(item.costMetrics?.length || 0);
      const gpuCount = Big(
        item.costMetrics?.reduce(
          (total, item) =>
            Big(item.gpuCountSpot || 0)
              .add(item.gpuCountOnDemand || 0)
              .add(item.gpuCountSpotFallback || 0)
              .add(total)
              .toNumber(),
          0
        ) || 0
      ).div(item.costMetrics?.length || 0);

      return {
        workloadName: item.workloadName!,
        workloadType: item.workloadType!,
        namespace: item.namespace!,
        cost: {
          podCount: Big(
            item.costMetrics?.reduce(
              (total, item) =>
                Big(item.podCountSpot || 0)
                  .add(item.podCountOnDemand || 0)
                  .add(item.podCountSpotFallback || 0)
                  .add(total)
                  .toNumber(),
              0
            ) || 0
          )
            .div(item.costMetrics?.length || 0)
            .toFixed(6),
          cpuCount: cpuCount.toFixed(6),
          ramGib: ramGib.toFixed(6),
          gpuCount: gpuCount.toFixed(6),
          cpuCost: Big(
            item.costMetrics?.reduce(
              (total, item) =>
                Big(item.cpuCostSpot || 0)
                  .add(item.cpuCostOnDemand || 0)
                  .add(item.cpuCostSpotFallback || 0)
                  .add(total)
                  .toNumber(),
              0
            ) || 0
          )
            .div(item.costMetrics?.length || 0)
            .toFixed(6),
          ramCost: Big(
            item.costMetrics?.reduce(
              (total, item) =>
                Big(item.ramCostSpot || 0)
                  .add(item.ramCostOnDemand || 0)
                  .add(item.ramCostSpotFallback || 0)
                  .add(total)
                  .toNumber(),
              0
            ) || 0
          )
            .div(item.costMetrics?.length || 0)
            .toFixed(6),
          gpuCost: Big(
            item.costMetrics?.reduce(
              (total, item) =>
                Big(item.gpuCostSpot || 0)
                  .add(item.gpuCostOnDemand || 0)
                  .add(item.gpuCostSpotFallback || 0)
                  .add(total)
                  .toNumber(),
              0
            ) || 0
          )
            .div(item.costMetrics?.length || 0)
            .toFixed(6),
          costOnDemand: costOnDemand.toFixed(6),
          costSpot: costSpot.toFixed(6),
          costSpotFallback: costSpotFallback.toFixed(6),
          totalCost: Big(costOnDemand)
            .add(costSpot)
            .add(costSpotFallback)
            .toFixed(6),
        },
      };
    }) || [];

  const _pageLimit = parseInt(pageLimit || '50', 10);

  return {
    ...fixture,
    items: items.slice(0, _pageLimit),
  };
};

export const demoNamespaceCostReport = async (
  clusterId: string,
  namespace: string,
  start: string,
  end: string
): Promise<NamespaceReportResponse> => {
  const workloadCostReport = await demoWorkloadCostReport(start, end);
  const costs = flatMap(
    workloadCostReport.items
      ?.filter((item) => item.namespace === namespace)
      .map((item) => item.costMetrics || []) || []
  );
  const grouped = groupBy(costs, (item) => item.timestamp);

  const emptyDailyCostItem: Omit<NamespaceCostItem, 'timestamp'> = {
    costOnDemand: '0.000000',
    costSpot: '0.000000',
    costSpotFallback: '0.000000',
    cpuCostOnDemand: '0.000000',
    cpuCostSpot: '0.000000',
    cpuCostSpotFallback: '0.000000',
    cpuCountOnDemand: '0.000000',
    cpuCountSpot: '0.000000',
    cpuCountSpotFallback: '0.000000',
    gpuCostOnDemand: '0.000000',
    gpuCostSpot: '0.000000',
    gpuCostSpotFallback: '0.000000',
    gpuCountOnDemand: '0.000000',
    gpuCountSpot: '0.000000',
    gpuCountSpotFallback: '0.000000',
    ramCostOnDemand: '0.000000',
    ramCostSpot: '0.000000',
    ramCostSpotFallback: '0.000000',
    ramGibOnDemand: '0.000000',
    ramGibSpot: '0.000000',
    ramGibSpotFallback: '0.000000',
  };

  const aggregated = mapValues(grouped, (value, key) => {
    return {
      ...emptyDailyCostItem,
      timestamp: key,
      costOnDemand: meanBy(value, (item) =>
        Big(item.costOnDemand || 0).toNumber()
      ),
      costSpot: meanBy(value, (item) => Big(item.costSpot || 0).toNumber()),
      costSpotFallback: meanBy(value, (item) =>
        Big(item.costSpotFallback || 0).toNumber()
      ),
      cpuCostOnDemand: meanBy(value, (item) =>
        Big(item.cpuCostOnDemand || 0).toNumber()
      ),
      cpuCostSpot: meanBy(value, (item) =>
        Big(item.cpuCostSpot || 0).toNumber()
      ),
      cpuCostSpotFallback: meanBy(value, (item) =>
        Big(item.cpuCostSpotFallback || 0).toNumber()
      ),
      cpuCountOnDemand: sumBy(value, (item) =>
        Big(item.cpuCountOnDemand || 0).toNumber()
      ),
      cpuCountSpot: sumBy(value, (item) =>
        Big(item.cpuCountSpot || 0).toNumber()
      ),
      cpuCountSpotFallback: sumBy(value, (item) =>
        Big(item.cpuCountSpotFallback || 0).toNumber()
      ),
      gpuCostOnDemand: meanBy(value, (item) =>
        Big(item.gpuCostOnDemand || 0).toNumber()
      ),
      gpuCostSpot: meanBy(value, (item) =>
        Big(item.gpuCostSpot || 0).toNumber()
      ),
      gpuCostSpotFallback: meanBy(value, (item) =>
        Big(item.gpuCostSpotFallback || 0).toNumber()
      ),
      gpuCountOnDemand: sumBy(value, (item) =>
        Big(item.gpuCountOnDemand || 0).toNumber()
      ),
      gpuCountSpot: sumBy(value, (item) =>
        Big(item.gpuCountSpot || 0).toNumber()
      ),
      gpuCountSpotFallback: sumBy(value, (item) =>
        Big(item.gpuCountSpotFallback || 0).toNumber()
      ),
      ramCostOnDemand: meanBy(value, (item) =>
        Big(item.ramCostOnDemand || 0).toNumber()
      ),
      ramCostSpot: meanBy(value, (item) =>
        Big(item.ramCostSpot || 0).toNumber()
      ),
      ramCostSpotFallback: meanBy(value, (item) =>
        Big(item.ramCostSpotFallback || 0).toNumber()
      ),
      ramGibOnDemand: sumBy(value, (item) =>
        Big(item.ramGibOnDemand || 0).toNumber()
      ),
      ramGibSpot: sumBy(value, (item) => Big(item.ramGibSpot || 0).toNumber()),
      ramGibSpotFallback: sumBy(value, (item) =>
        Big(item.ramGibSpotFallback || 0).toNumber()
      ),
    };
  });

  const dailyCostItems: NamespaceCostItem[] = [
    ...(Object.values(aggregated) as unknown as NamespaceCostItem[]),
  ];

  const avgCost = Big(
    meanBy(dailyCostItems, (item) =>
      Big(item.costOnDemand || 0)
        .add(item.costSpot || 0)
        .add(item.costSpotFallback || 0)
        .toNumber()
    )
  ).toFixed(6);

  const avgCpuCost = Big(
    meanBy(dailyCostItems, (item) =>
      Big(item.cpuCostOnDemand || 0)
        .add(item.cpuCostSpot || 0)
        .add(item.cpuCostSpotFallback || 0)
        .toNumber()
    )
  ).toFixed(6);

  const avgGpuCost = Big(
    meanBy(dailyCostItems, (item) =>
      Big(item.gpuCostOnDemand || 0)
        .add(item.gpuCostSpot || 0)
        .add(item.gpuCostSpotFallback || 0)
        .toNumber()
    )
  ).toFixed(6);

  const avgRamCost = Big(
    meanBy(dailyCostItems, (item) =>
      Big(item.ramCostOnDemand || 0)
        .add(item.ramCostSpot || 0)
        .add(item.ramCostSpotFallback || 0)
        .toNumber()
    )
  ).toFixed(6);

  const totalCost = Big(
    sumBy(dailyCostItems, (item) =>
      Big(item.costOnDemand || 0)
        .add(item.costSpot || 0)
        .add(item.costSpotFallback || 0)
        .toNumber()
    )
  ).toFixed(6);

  const totalWorkloadsCount = size(
    uniqBy(
      workloadCostReport.items?.filter(
        (item) => item.namespace === namespace
      ) || [],
      (item) =>
        (item.namespace || '') +
        (item.workloadType || '') +
        (item.workloadName || '')
    )
  ).toString();

  return {
    clusterId,
    namespace,
    costSummary: {
      avgCost,
      avgCpuCost,
      avgGpuCost,
      avgRamCost,
      totalCost,
      totalWorkloadsCount,
    },
    dailyCostItems: dailyCostItems.map((item) => {
      const _item: any = { ...item };
      const values = Object.keys(_item);
      for (const key of values) {
        if (typeof _item[key as keyof typeof _item] === 'number') {
          _item[key] = (_item[key] as number).toFixed(6);
        }
      }
      return _item as NamespaceCostItem;
    }),
  };
};

export const demoNamespacesCostsSummary = async () => {
  const json = await import('../generated/demo/namespaces-costs-summary.json');

  return namespacesCostsSummaryModifier(JSON.stringify(json));
};

export const demoNamespacesTotalCosts = async (
  startTime?: string,
  endTime?: string
) => {
  const json = await import(
    '../generated/demo/namespaces-costs-total-costs.json'
  );

  return namespacesTotalCostsModifier(
    JSON.stringify(json),
    startTime,
    endTime,
    undefined
  );
};

export const demoNamespacesNetworkCostsSummary = async () => {
  const json = await import(
    '../generated/demo/namespaces-network-costs-summary.json'
  );

  return namespacesNetworkCostsSummaryModifier(JSON.stringify(json));
};

export const demoNamespacesNetworkTotalCosts = async (
  startTime?: string,
  endTime?: string
) => {
  const json = await import(
    '../generated/demo/namespaces-network-total-costs.json'
  );

  return namespacesNetworkTotalCostsModifier(
    JSON.stringify(json),
    startTime,
    endTime,
    undefined
  );
};

export const demoWorkloadLabelNames = async () => {
  const json = await import('../generated/demo/workload-label-names.json');
  return json as WorkloadLabelNamesResponse;
};

export const demoWorkloadLabelValues = async () => {
  const json = await import('../generated/demo/workload-label-values.json');
  return json as WorkloadLabelValuesResponse;
};

export const demoAllocationGroups = async () => {
  const json = await import('../generated/demo/allocation-groups.json');
  return allocationGroupsModifier(JSON.stringify(json), {});
};

export const demoAllocationGroupSummaries = async (groupId?: string) => {
  const json = await import(
    '../generated/demo/allocation-group-summaries.json'
  );
  let items = json.items;

  if (groupId) {
    items = json.items.filter((item) => item.groupId === groupId);
  }

  return { ...json, items } as CostReportAllocationGroupSummariesResponse;
};

export const demoAllocationGroupTotalCosts = async (
  startDate?: string,
  endDate?: string
) => {
  const json = await import(
    '../generated/demo/allocation-group-total-costs.json'
  );
  return allocationGroupComputeCostTotalCostsModifier(
    JSON.stringify(json),
    startDate,
    endDate
  );
};

export const demoAllocationGroupTimedSummaries = async (
  startDate?: string,
  endDate?: string,
  groupId?: string
) => {
  const json = await import(
    '../generated/demo/allocation-group-timed-summaries.json'
  );
  let items = json.items;

  if (groupId) {
    items = json.items.filter((item) => item.groupId === groupId);
  }

  return allocationGroupComputeCostTimedSummariesModifier(
    JSON.stringify({ ...json, items }),
    startDate,
    endDate
  );
};

export const demoAllocationGroupWorkloads = async (groupId: string) => {
  const { default: json } = await import(
    '../generated/demo/allocation-group-workloads.json'
  );
  const groupWorkloads = json.find((workload) => workload.groupId === groupId);

  if (groupWorkloads) {
    return allocationGroupComputeCostWorkloadsModifier(
      JSON.stringify(groupWorkloads),
      {}
    );
  }

  return {};
};

export const demoAllocationGroupNetworkCostSummaries = async () => {
  const json = await import(
    '../generated/demo/allocation-group-network-cost-summaries.json'
  );
  return allocationGroupNetworkCostSummariesModifier(JSON.stringify(json), {});
};

export const demoAllocationGroupNetworkCostWorkloads = async (
  groupId: string
) => {
  const { default: json } = await import(
    '../generated/demo/allocation-group-network-cost-workloads.json'
  );
  const groupWorkloads = json.find((workload) => workload.groupId === groupId);

  if (groupWorkloads) {
    return allocationGroupNetworkCostWorkloadsModifier(
      JSON.stringify(groupWorkloads),
      {}
    );
  }

  return {};
};

export const demoAllocationGroupsEfficiencySummary = async (
  startTime: string
) => {
  const json = await import(
    '../generated/demo/allocation-group-efficiency-summary.json'
  );

  return allocationGroupsEfficiencyModifier(
    JSON.stringify(json),
    {},
    dayjs(startTime)
  );
};

export const demoAllocationGroupsWorkloadsEfficiency = async () => {
  const { default: json } = await import(
    '../generated/demo/allocation-groups-workloads-efficiency.json'
  );

  return allocationGroupWorkloadsEfficiencyModifier(JSON.stringify(json), {});
};

export const demoClusterPolicies = async () => {
  const json = await import('../generated/demo/autoscaler-policies.json');
  return policiesModifier(JSON.stringify(json), {});
};

export const demoAdvancedEvictorConfig = async () => {
  const { default: json } = await import(
    '../generated/demo/advanced-evictor-config.json'
  );
  return advancedEvictorConfigModifier(JSON.stringify(json), {});
};

export const demoClusterNodeSelectionList =
  (): Promise<NodeSelectionListResponse> => {
    return import('../generated/demo/node-selection-list.json');
  };

export const demoClusterFilterInstanceTypes =
  async (): Promise<UpdateSelectionTemplateResponse> => {
    const json = await import('../generated/demo/filter-instance-types.json');
    return json as UpdateSelectionTemplateResponse;
  };

export const demoClusterNodeConfigList = async () => {
  return await import('../generated/demo/node-config-list.json');
};

export const demoClusterNodeConfig = async (id: string) => {
  const json = await import('../generated/demo/node-config-list.json');
  const nodeConfigById = keyBy(json, 'id');
  return nodeConfigById[id];
};

export const demoClusterNodeConfigSuggestions = async () => {
  return await import('../generated/demo/node-configuration-suggestions.json');
};

export const demoClusterNodeConfigMaxPodsFormulaPresets = async () => {
  return await import(
    '../generated/demo/node-config-max-pods-formula-presets.json'
  );
};

export const demoClusterNodeList = async () => {
  const json = await import('../generated/demo/node-list.json');
  return {
    items: Array.from(json.default).map((n) => {
      return nodeModifier(JSON.stringify(n), {});
    }) as NodeResponse[],
  };
};

export const demoClusterNode = async (id: string) => {
  const json = await import('../generated/demo/node-list.json');
  const nodesById = keyBy(json.default, 'id');
  return nodeModifier(JSON.stringify(nodesById[id]), {});
};

export const demoClusterPricingNodes = async () => {
  const json = await import('../generated/demo/pricing-cluster-nodes.json');
  return json as ClusterNodesPricingResponse;
};

export const demoClusterGenerateRebalancingPlan = async (
  nodeIds: string[]
): Promise<RebalancingAccepted> => {
  const nodeListJson = await import('../generated/demo/node-list.json');
  const rebalancingWorkloadListJson = await import(
    '../generated/demo/rebalancing-workload-list.json'
  );
  const nodesById = keyBy(nodeListJson, 'id');

  const nodeWithPods = keyBy(
    uniqBy(
      flatten(rebalancingWorkloadListJson.workloads.map((w) => w.nodes)),
      'id'
    ).map((n) => pick(n, ['id', 'totalPods'])),
    'id'
  );

  const blueRebalancingNodes: RebalancingNode[] = nodeIds.map((id) => {
    const node = nodesById[id] as unknown as NodeResponse;
    const resourceOffering = getNodeResourceOffering(node);

    return {
      isControlPlane: false,
      instanceType: node.instanceType!,
      milliCpu: node.resources!.cpuCapacityMilli!,
      memoryMib: node.resources!.memCapacityMib!,
      isSpot: resourceOffering === NodeResourceOffering.SPOT,
      priceHourly: node.instancePrice!,
      isLegacy: false,
      totalPods:
        node.id! in nodeWithPods ? nodeWithPods[node.id!].totalPods : 0,
      name: node.name!,
      managedBy: RebalancingNodeManagedByEnum.CASTAI,
      totalProblematicPods: 0,
      createdAt: node.createdAt!,
      isSpotFallback: resourceOffering === NodeResourceOffering.FALLBACK,
      id: node.id,
    };
  });

  const blueTotals = getRebalancingConfigurationTotal(blueRebalancingNodes);

  const greenRebalancingNodes: RebalancingNode[] =
    nodeIds.length > 1
      ? getRebalancingGreenNodes(blueRebalancingNodes)
      : blueRebalancingNodes;

  const greenTotals = getRebalancingConfigurationTotal(greenRebalancingNodes);

  const rebalancingPlanJson = await import(
    '../generated/demo/rebalancing-plan.json'
  );

  const plan: RebalancingPlan = {
    ...(rebalancingPlanJson as RebalancingPlan),
    rebalancingNodeIds: nodeIds,
    configurations: {
      blue: {
        nodes: blueRebalancingNodes,
        totals: blueTotals,
      },
      green: {
        nodes: greenRebalancingNodes,
        totals: greenTotals,
      },
      diff: {
        priceHourly: (
          parseFloat(blueTotals.priceHourly) -
          parseFloat(greenTotals.priceHourly)
        ).toString(),
        priceMonthly: (
          parseFloat(blueTotals.priceMonthly) -
          parseFloat(greenTotals.priceMonthly)
        ).toString(),
        savingsPercentage: (
          100 -
          (parseFloat(greenTotals.priceHourly) * 100) /
            parseFloat(blueTotals.priceHourly)
        ).toString(),
      },
    },
  };

  const _rebalancingPlan = JSON.parse(
    replaceRebalancingPlanIdTokens(JSON.stringify(plan), uuidv4())
  );

  window.sessionStorage.setItem(
    `demo-rebalancing-plan-${_rebalancingPlan.rebalancingPlanId}`,
    JSON.stringify(_rebalancingPlan)
  );

  return {
    rebalancingPlanId: _rebalancingPlan.rebalancingPlanId,
  };
};

export const demoClusterRebalancingPlans = () => ({
  items: [],
});

export const demoClusterRebalancingPlan = (id: string) => {
  const generatedPlanJson = window.sessionStorage.getItem(
    `demo-rebalancing-plan-${id}`
  );
  if (generatedPlanJson) {
    try {
      return rebalancingPlanModifier(generatedPlanJson, {});
    } catch (e) {
      console.error(e);
    }
  }
};

export const demoClusterRebalancingWorkloads = async () => {
  const rebalancingWorkloadListJson = await import(
    '../generated/demo/rebalancing-workload-list.json'
  );
  const parsedClusterId = JSON.parse(
    replaceClusterIdTokens(JSON.stringify(rebalancingWorkloadListJson))
  );
  return {
    clusterId: parsedClusterId.clusterId,
    workloads: parsedClusterId.workloads.map((w: Workload) =>
      rebalancingWorkloadModifier(JSON.stringify(w), {})
    ),
  };
};

export const demoAutoscalerFeatures = async () => {
  const autoscalerFeatures = await import(
    '../generated/demo/autoscaler-features.json'
  );
  return autoscalerFeatures;
};

export const demoClusterEstimatedSavings = async () => {
  const json = await import('../generated/demo/estimated-savings.json');
  return estimatedSavingsModifier(JSON.stringify(json), {});
};

export const demoRightsizingSummary = async () => {
  const json = await import('../generated/demo/rightsizing-summary.json');
  return json as RightsizingSummaryResponse;
};

export const demoClusterEstimatedSavingsHistory = async () => {
  const json = await import('../generated/demo/estimated-savings-history.json');
  return estimatedSavingsHistoryModifier(JSON.stringify(json), {});
};

export const demoClusterSecurityInsightsBestPractices = async () => {
  const json = await import(
    '../generated/demo/security-insights-best-practices.json'
  );
  return securityInsightsBestPracticesModifier(JSON.stringify(json), {});
};

export const demoClusterSecurityInsightsBestPracticesFilters = async () => {
  const json = await import(
    '../generated/demo/security-insights-best-practices-filters.json'
  );
  return securityInsightsBestPracticesFiltersModifier(JSON.stringify(json), {});
};

export const demoClusterSecurityInsightsBestPractice = async (name: string) => {
  const json = await import(
    '../generated/demo/security-insights-best-practices-details-summary.json'
  ).then((m) => m.default);
  if (name in json) {
    const entry = (json as Record<string, BestPracticeCheckDetails>)[name];

    return securityInsightsBestPracticesDetailsModifier(
      JSON.stringify(entry),
      {}
    );
  }
};

export const demoClusterSecurityInsightsBestPracticeResources = async (
  name: string
) => {
  const json = await import(
    '../generated/demo/security-insights-best-practices-resources.json'
  ).then((m) => m.default);
  if (name in json) {
    const entry = (
      json as Record<string, SecurityInsightsCheckResourcesResponse>
    )[name];

    return securityInsightsBestPracticesResourcesModifier(
      JSON.stringify(entry),
      {}
    );
  }
};

export const demoClusterSecurityInsightsImages = async () => {
  const json = await import('../generated/demo/security-insights-images.json');
  return securityInsightsImagesModifier(JSON.stringify(json), {});
};

export const demoClusterSecurityInsightsImageDetails = async (
  tagId: string
) => {
  const json = getImageDetails(tagId);
  return securityInsightsImageDetailsModifier(JSON.stringify(json), {});
};

export const demoClusterSecurityInsightsImageDigests = async (
  tagId: string
) => {
  const json = getImageDigests(tagId);
  return securityInsightsImageDigestsModifier(JSON.stringify(json), {});
};

export const demoClusterSecurityInsightsImageVulnerabilities = async () => {
  const json = await import(
    '../generated/demo/security-insights-image-vulnerabilities.json'
  );
  return securityInsightsImageVulnerabilitiesModifier(JSON.stringify(json), {});
};

export const demoClusterSecurityInsightsImageVulnerabilityDetails = async (
  packageId: string
) => {
  const json = getImageVulnerabilityDetails(packageId);
  return securityInsightsImageVulnerabilityDetailsModifier(
    JSON.stringify(json),
    {}
  );
};

export const demoClusterSecurityInsightsImagePackages = async () => {
  const json = await import(
    '../generated/demo/security-insights-image-packages.json'
  );
  return securityInsightsImagePackagesModifier(JSON.stringify(json), {});
};

export const demoClusterSecurityInsightsImageResources = async () => {
  const json = await import(
    '../generated/demo/security-insights-image-resources.json'
  );
  return securityInsightsImageResourcesModifier(JSON.stringify(json), {});
};

export const demoClusterSecurityInsightsBaseImageAlternatives = async () => {
  const { default: json } = await import(
    '../generated/demo/security-insights-base-image-alternatives.json'
  );
  return json;
};

export const demoOrganizationSecurityInsightsAttackPaths = async () => {
  const { default: json } = await import(
    '../generated/demo/security-insights-attack-paths.json'
  );
  return securityInsightsAttackPathsModifier(JSON.stringify(json), {});
};

export const demoOrganizationSecurityInsightsAttackPathDetails = async () => {
  const { default: json } = await import(
    '../generated/demo/security-insights-attack-path-details.json'
  );
  return securityInsightsAttackPathDetailsModifier(JSON.stringify(json), {});
};

export const demoOrganizationSecurityInsightsResourceDetails = async (
  id: string
) => {
  const json = await import(
    '../generated/demo/security-insights-resource-details.json'
  ).then((m) => m.default);
  if (id in json) {
    const entry = (
      json as Record<string, SecurityInsightsResourceDetailsResponse>
    )[id];

    return securityInsightsResourceDetailsModifier(JSON.stringify(entry), {});
  }
};

export const demoOrganizationSecurityInsightsIntegrations = async () => {
  const { default: json } = await import(
    '../generated/demo/security-insights-integrations.json'
  );
  return securityInsightsIntegrationsModifier(JSON.stringify(json), {});
};

export const demoOrganizationSecurityInsightsBestPracticeOverview =
  async () => {
    const { default: json } = await import(
      '../generated/demo/security-insights-best-practice-overview.json'
    );
    return securityInsightsBestPracticeOverviewModifier(
      JSON.stringify(json),
      {}
    );
  };

export const demoOrganizationSecurityInsightsImageSecurityOverview =
  async () => {
    const { default: json } = await import(
      '../generated/demo/security-insights-image-security-overview.json'
    );
    return securityInsightsImageSecurityOverviewModifier(
      JSON.stringify(json),
      {}
    );
  };

export const demoOrganizationSecurityInsightsAttackPathsOverview = async () => {
  const { default: json } = await import(
    '../generated/demo/security-insights-attack-paths-overview.json'
  );
  return securityInsightsAttackPathsOverviewModifier(JSON.stringify(json), {});
};

export const demoOrganizationSecurityInsightsClustersSettings = async () => {
  const { default: json } = await import(
    '../generated/demo/security-insights-clusters-settings.json'
  );
  return securityInsightsClustersSettingsModifier(JSON.stringify(json), {});
};

export const demoNodeConstraints = async () => {
  const json = await import('../generated/demo/node-constraints.json');
  return nodeConstraintsModifier(JSON.stringify(json), {});
};

export const demoOrganizationSecurityInsightsAgents = async () => {
  const { default: json } = await import(
    '../generated/demo/security-insights-agents.json'
  );
  return json;
};

export const demoOrganizationSecurityInsightsWorkloads = async () => {
  const { default: json } = await import(
    '../generated/demo/security-insights-workloads.json'
  );
  return securityInsightsWorkloadsModifier(JSON.stringify(json), {});
};

export const demoWoopWorkloadsList = async () => {
  const { default: json } = await import(
    '../generated/demo/woop-workloads-list.json'
  );
  return woopWorkloadsModifier(JSON.stringify(json));
};

export const demoWoopWorkloadsSummary = async () => {
  const { default: json } = await import(
    '../generated/demo/woop-workloads-summary.json'
  );
  return json;
};

export const demoWoopWorkloadDetails = async () => {
  const { default: json } = await import(
    '../generated/demo/woop-workload-details.json'
  );
  return woopWorkloadModifier(JSON.stringify(json));
};

export const demoWoopScalingPoliciesList = async () => {
  const { default: json } = await import(
    '../generated/demo/woop-scaling-policies-list.json'
  );
  return json as ScalingPoliciesListResponse;
};

export const demoClusterRuntimeSecurityAnomalies = async () => {
  const json = await import(
    '../generated/demo/runtime-security-anomalies.json'
  );
  return runtimeSecurityAnomaliesModifier(JSON.stringify(json), {});
};

export const demoClusterRuntimeSecurityAnomalyDetails = async (id: string) => {
  const anomaly = (await demoClusterRuntimeSecurityAnomalies()).anomalies!.find(
    (a) => a.id === id
  );
  return runtimeSecurityAnomalyDetailsModifier(
    JSON.stringify({ anomaly, events: [] }),
    {}
  );
};

export const demoClusterRuntimeSecurityAnomalyEvents = async (
  anomalyId: string
) => {
  const json = await import(
    '../generated/demo/runtime-security-anomaly-events.json'
  );
  const anomaly = (await demoClusterRuntimeSecurityAnomalies()).anomalies!.find(
    (a) => a.id === anomalyId
  );
  return runtimeSecurityAnomalyEventsModifier(JSON.stringify(json), {
    events: json.events.map((event) => ({
      ...event,
      namespace: anomaly?.namespace,
      podName: `${anomaly?.workload}-6478c75b68-bzg6hz`,
      containerName: anomaly?.workload,
      process: anomaly?.workload,
    })),
  });
};

export const demoRuntimeSecurityEvents = async () => {
  const { default: json } = await import(
    '../generated/demo/runtime-security-events.json'
  );
  return json;
};

export const demoClusterRuntimeSecurityAnomaliesOverview = async () => {
  const { default: json } = await import(
    '../generated/demo/runtime-security-anomalies-overview.json'
  );
  return runtimeSecurityAnomaliesOverviewModifier(JSON.stringify(json), {});
};

export const demoClusterRuntimeSecurityRules = async () => {
  const json = await import('../generated/demo/runtime-security-rules.json');
  return runtimeSecurityRulesModifier(JSON.stringify(json), {});
};

export const demoClusterRuntimeSecurityRuleDetails = async (id: string) => {
  const rule = (await demoClusterRuntimeSecurityRules()).rules!.find(
    (r) => r.id === id
  );
  return runtimeSecurityRuleDetailsModifier(JSON.stringify({ rule }), {});
};

export const demoCostReportWorkloadsSummary = async () => {
  const json = await import(
    '../generated/demo/cost-report-workloads-summary.json'
  );
  return costReportWorkloadSummariesModifier(JSON.stringify(json));
};

export const demoScheduledRebalancingListSchedules = async () => {
  const json = await import(
    '../generated/demo/scheduled-rebalancing-schedules-list.json'
  );
  return json;
};

export const demoClusterAnomalies = async (startTime?: string) => {
  const json = await import('../generated/demo/cluster-cost-anomalies.json');

  return clusterAnomaliesModifier(JSON.stringify(json), dayjs(startTime));
};
