import { Button, Icons, Tooltip, useDrawerInstance } from '@cast/design-system';
import { BestPracticeCheck } from '@cast/types';

import { PermissionType, useRBAC } from 'core/rbac';

import { useExceptionsManagementDrawer } from '../../useExceptionsManagementDrawer';

type Props = {
  check: BestPracticeCheck;
  testId?: string;
};

export const ExceptionsButton = ({
  check,
  testId = 'check-details-exceptions-button',
}: Props) => {
  const { close } = useDrawerInstance();
  const [canEdit] = useRBAC(PermissionType.EDIT);
  const { openExceptionsManagementDrawer } = useExceptionsManagementDrawer();

  const isDisabled = !canEdit;

  const button = (
    <Button
      testId={testId}
      variant="ghost"
      size="small"
      startIcon={<Icons.ShieldSlash />}
      onClick={() => {
        openExceptionsManagementDrawer(check);
        close();
      }}
      disabled={isDisabled}
    >
      Exceptions
    </Button>
  );

  if (isDisabled) {
    return (
      <Tooltip
        title="You don't have permission for this feature."
        placement="left"
      >
        <div>{button}</div>
      </Tooltip>
    );
  }

  return button;
};
