import { ThemeOptions, alpha } from '@mui/material';

import { tableMeasurements } from './constants';
import { DsShadow } from '../../theme/shadows/shadowsTheme';

export const tableTheme: ThemeOptions['components'] = {
  DsTable: {
    styleOverrides: {
      root: ({ ownerState, theme }) => {
        const {
          headerFont: _headerFont,
          bodyFont: _bodyFont,
          footerFont: _footerFont,
          headerHeight,
          rowHeight: _rowHeight,
          footerHeight,
          horizontalCellPadding,
          headerIconSize,
          groupHeaderHeight,
        } = tableMeasurements[ownerState.sizing];
        const headerFont = theme.typography[_headerFont];
        const bodyFont = theme.typography[_bodyFont];
        const footerFont = theme.typography[_footerFont];
        const rowHeight = ownerState.rowHeight || _rowHeight;
        return {
          position: 'relative',
          isolation: 'isolate',
          overflow: 'visible',
          '--rowHeight': `${rowHeight}px`,
          '& .DS-Table-hScroller': {
            filter: 'drop-shadow(0px 1px 1px rgba(109, 127, 136, 0.2))',
          },
          // Floating column styles
          '& .DS-Table-ActionIcon': {
            width: 24,
            height: 24,
            borderRadius: '4px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'white',
            cursor: 'pointer',
            fontSize: 16,
            '& > .DsIconButton-root': {
              width: 24,
              height: 24,
            },
            '&.DS-Table-actionDisabled': {
              cursor: 'initial',
            },
          },

          '&.DS-Table-floatingColumnSticky': {
            '& .DS-Table-floatingCell': {
              position: 'sticky',
              overflow: 'visible',
              right: 0,
              opacity: 0,
              paddingLeft: 0,
              paddingRight: 10,
              '& .DS-Table-Cell': {
                overflow: 'visible',
                display: 'flex',
                justifyContent: 'flex-end',
              },
              '& .DS-Table-ActionIcon, & .DsIconButton-root': {
                boxShadow: theme.shadows[DsShadow.DROPDOWN],
              },
              '& .DsIconButton-root': {
                backgroundColor: 'white',
              },
            },

            '& .DS-Table-BodyRowWrapper:hover': {
              '& .DS-Table-floatingCell': {
                opacity: 1,
                backgroundColor: 'unset',
              },
            },
          },
          '& .DS-Table-Wrapper': {
            width: '100%',
            overflow: 'auto',
          },
          '& .DS-Table-HorizontalScroller': {
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            width: 'var(--hScrollerWidth)',
          },
          '& .DS-Table-OuterHeaderWrapper': {
            position: 'relative',
            '&-withGroupHeaders': {
              marginBottom: -groupHeaderHeight,
              zIndex: 1,
            },
          },
          // General row styles
          '& .DS-Table-RowWrapper': {
            position: 'relative',
          },
          // General cell styles
          '& .DS-Table-CellWrapper': {
            alignItems: 'center',
            display: 'flex',
            overflow: 'hidden',
            boxSizing: 'border-box',
            paddingLeft: horizontalCellPadding,
            paddingRight: horizontalCellPadding,
            backgroundColor: 'white',
          },
          '& .DS-Table-lockedCell': {
            position: 'sticky',
            left: `var(--startOffset)`,
            zIndex: 1,
          },
          '& .os-scrollbar-horizontal': {
            paddingLeft: 'var(--lockedColumnsWidth)',
          },
          '& .DS-Table-Cell': {
            overflow: 'hidden',
          },
          '& .DS-Table-stopClickPropagation': {
            cursor: 'initial',
          },
          // Group header
          '& .DS-Table-GroupHeadersWrapper': {
            display: 'flex',
          },
          '& .DS-Table-GroupHeaders': {
            position: 'relative',
            display: 'flex',
            width: 'var(--tableWidth)',
            flexWrap: 'nowrap',
          },
          '& .DS-Table-GroupHeaderWrapper': {
            overflow: 'visible',
            padding: 0,
            // Required for group header offset to work properly
            width: 0,
            position: 'relative',
          },
          '& .DS-Table-LockedGroupHeaders': {
            position: 'sticky',
            minWidth: 0,
            left: 0,
            width: 'var(--lockedColumnsWidth)',
            display: 'flex',
          },
          '& .DS-Table-ScrollableGroupHeaders': {
            width: 'var(--scrollableColumnsWidth)',
            clipPath: 'inset(0 0 0 var(--scrollLeft))',
            display: 'flex',
          },
          '& .DS-Table-loadingRow': {
            height: 'var(--rowHeight)',
          },
          '& .DS-Table-GroupHeader': {
            display: 'flex',
            direction: 'row',
            height: groupHeaderHeight,
            padding: '2px 16px',
            backgroundColor: 'white',
            border: '1px solid',
            borderColor: theme.palette.grey[100],
            borderRadius: '4px 4px 0 0',
            position: 'relative',
            color: theme.palette.grey[400],
            borderBottom: 'none',
            boxSizing: 'border-box',
            ...theme.typography.L10R,
            '&:after': {
              content: '""',
              width: '100%',
              background:
                'linear-gradient(0deg, rgba(8, 41, 57, 0.03) 0%, rgba(8, 41, 57, 0) 72.92%)',
              position: 'absolute',
              inset: 'auto 0 0 0',
              height: '7px',
            },
          },
          '& .DS-Table-TopPeripheralsWrapper': {
            width: '100%',
            overflow: 'hidden',
          },
          '& .DS-Table-TopPeripherals': {
            minWidth: '100%',
            width: 'var(--tableWidth)',
          },
          '& .DS-Table-LockedColumnsDivider': {
            width: '35px',
            height: '100%',
            borderLeft: '1px solid',
            borderColor: theme.palette.grey[300],
            position: 'absolute',
            left: 'var(--lockedColumnsWidth)',
            background:
              'linear-gradient(90deg, rgba(8, 41, 57, 0.05) 0%, rgba(8, 41, 57, 0.00) min(var(--scrollLeft), 72.92%))',
          },
          // Overlay styles
          '& .DS-Table-Overlay': {
            position: 'absolute',
            inset: 0,
            pointerEvents: 'none',
            zIndex: 3,
          },
          '& .DS-Table-ResizerLine': {
            display: 'none',
            position: 'absolute',
            top: 0,
            bottom: 0,
            width: 16,
            background:
              'linear-gradient(180deg, rgba(131, 146, 153, 0.03) 0%, rgba(131, 146, 153, 0.00) 100%)',
            borderLeft: '1px solid',
            borderColor: theme.palette.grey[100],
            '&.DS-Table-resizing': {
              display: 'initial',
            },
          },
          '& .DS-Table-ResizerIconWrapper': {
            height: headerHeight,
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 0,
          },
          // Header styles
          '& .DS-Table-HeaderRowWrapper': {
            display: 'flex',
            alignItems: 'stretch',
            minWidth: '100%',
            width: 'max-content',
            borderBottom: '1px solid',
            borderColor: theme.palette.grey[100],
            minHeight: headerHeight,
            borderRadius: '4px 4px 0 0',
            backgroundColor: 'white',
            // clipping overflow is needed for rounded borders to be visible, 'overflow: hidden' would not work with sticky columns as it implicitly creates scroll container
            overflow: 'clip',
          },
          '& .DS-Table-headerHidden': {
            display: 'none',
          },
          '& .DS-Table-HeaderRow': {
            display: 'flex',
            flexDirection: 'row',
            width: 'max-content',
          },
          '& .DS-Table-HeaderCellWrapper-firstGroupColumn': {
            borderLeft: `1px solid ${theme.palette.grey[100]}`,
          },
          '& .DS-Table-HeaderCellWrapper-lastGroupColumn': {
            borderRight: `1px solid ${theme.palette.grey[100]}`,
          },
          '& .DS-Table-HeaderCell-hasInfoTooltip, & .DS-Table-HeaderCell-sortable':
            {
              '& .DS-Table-HeaderCell': {
                display: 'flex',
                alignItems: 'center',
              },
            },
          '& .DS-Table-HeaderCell': headerFont,
          '& .DS-Table-HeaderTitle': {
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          },
          '& .DS-Table-InfoIcon': {
            fill: theme.palette.grey[800],
          },
          '& .DS-Table-HeaderTitle + .Ds-Table-HeaderIcon-root': {
            marginLeft: 4,
          },
          '& .Ds-Table-HeaderIcon-root + .Ds-Table-HeaderIcon-root': {
            marginLeft: 2,
          },
          '& .Ds-Table-HeaderIcon-root': {
            fontSize: headerIconSize,
            minWidth: '1em',
            minHeight: '1em',
          },
          '& .DS-Table-GroupHeaderIcon': {
            fill: theme.palette.grey[400],
            stroke: theme.palette.grey[400],
          },
          // Body styles
          '& .DS-Table-OverlayContainer': {
            position: 'relative',
          },
          '& .DS-Table-Body': {
            position: 'relative',
            flex: '1 0 auto',
            width: '100%',
            backgroundColor: 'white',
            overflow: 'hidden',
          },
          '&:not(.DS-Table-hasFooter) .DS-Table-Body': {
            borderBottomLeftRadius: '4px',
            borderBottomRightRadius: '4px',
          },
          // Body row styles
          '& .DS-Table-BodyRowWrapper': {
            width: 'var(--tableWidth)',
            '&:not(.DS-Table-firstRow)': {
              borderTop: '1px solid',
              borderColor: theme.palette.grey[100],
            },
            minHeight: ownerState.rowHeight,
            height: 'auto',
            boxSizing: 'border-box',
            '&.DS-Table-lastRow': {
              paddingBottom: 6, // The height of the scrollbar, prevents scrollbar from covering content
            },
          },
          '& .DS-Table-rowSelectionDisabled': {
            opacity: 0.6,
          },
          '& .DS-Table-BodyRow': {
            minHeight: ownerState.rowHeight,
            display: 'flex',
            flexDirection: 'row',
            width: 'var(--tableWidth)',
            alignItems: 'stretch',
            '&:hover': {
              backgroundColor: theme.palette.grey[50],
            },
          },
          '& .DS-Table-ClickableRow': {
            cursor: 'pointer',
          },
          '& .DS-Table-rowExpanded': {
            backgroundColor: theme.palette.grey[50],
            '&:not(.DS-Table-firstRow)': {
              borderTop: `1px solid ${theme.palette.grey[200]}`,
            },
          },
          '& .DS-Table-ExpandedPanelWrapper': {
            overflow: 'hidden',
            height: 'min-content',
            backgroundColor: alpha(theme.palette.grey[100], 0.35),
            '&.open': {
              borderTop: `1px solid ${theme.palette.grey[200]}`,
            },
            '& .DS-Table-CellWrapper': {
              backgroundColor: 'inherit',
            },
          },
          '& .DS-Table-BodyCellWrapper, & .DS-Table-HeaderCellWrapper': {
            display: 'flex',
            alignItems: 'center',
          },
          '& .DS-Table-BodyCell': {
            whiteSpace: 'nowrap',
            width: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            ...(typeof bodyFont === 'object'
              ? bodyFont
              : { fontFamily: bodyFont }),
          },
          // Footer styles
          '& .DS-Table-FooterRowWrapper': {
            display: 'flex',
            alignItems: 'stretch',
            width: 'max-content',
            height: footerHeight,
            backgroundColor: theme.palette.grey[50],
            borderTop: '1px solid',
            borderColor: theme.palette.grey[100],
            borderRadius: '0 0 4px 4px',
          },
          '& .DS-Table-FooterRow': {
            display: 'flex',
            height: '100%',
            alignItems: 'center',
            width: 'max-content',
          },
          '& .DS-Table-FooterCell': footerFont,
          // Scroll bar styles
          '& .DS-Table-Scroller': {
            '& .os-scrollbar-vertical': {
              transition: 'left 0ms, right 0ms',
            },
          },
          // Tree table
          '& .DS-Table-TreeRow-root': {
            position: 'relative',
            backgroundColor: 'white',
          },
          '& .DS-Table-TreeChildRowsWrapper': {
            transition: 'height 100ms',
            backgroundColor: theme.palette.grey[50],
            overflow: 'hidden',
            position: 'relative',
            '&-rootLevel:before': {
              content: '""',
              width: '100%',
              background:
                'linear-gradient(180deg, rgba(8, 41, 57, 0.03) 0%, rgba(8, 41, 57, 0) 72.92%)',
              position: 'absolute',
              top: 0,
              height: '15px',
            },
          },
          '& .DS-Table-SubRow': {
            backgroundColor: 'unset',
          },
          '& .DS-Table-TreeRow': {
            position: 'relative',
          },
          '& .DS-TreeTable-ExpanderIcon': {
            position: 'absolute',
            zIndex: 1,
            top: '50%',
            transform: 'translateY(-50%)',
          },
          '& .DS-Table-TreeMarker': {
            position: 'absolute',
            backgroundColor: theme.palette.grey[300],
            zIndex: 1,
          },
          '& .DS-Table-TreeMarker-vertical': {
            width: 1,
          },
          '& .DS-Table-TreeMarker-horizontal': {
            height: 1,
            top: '50%',
            transform: 'translateY(-50%)',
          },
          '& .DS-Table-DraggableRowContainer': {
            // TODO: do we really need a custom color?
            backgroundColor: '#AEB6BA',
            '&:empty': {
              minHeight: 'calc(var(--child-height))',
              boxSizing: 'border-box',
            },
          },
          '& .DS-Table-DraggableCloneContainer': {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            pointerEvents: 'none',
            overflow: 'visible',
          },
        };
      },
    },
  },
};
