import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import { endOfDay, format, isEqual, startOfDay, sub } from 'date-fns';
import pick from 'lodash/pick';
import { DateRange } from 'react-day-picker';

import DatePickerContainer, {
  DatePickerContainerProps,
} from '../components/DatePickerContainer';
import DatePickerFooter from '../components/DatePickerFooter';
import DayPickerWrapper from '../components/DayPickerWrapper';
import FilterPresetsList from '../components/FilterPresetsList';
import RangePickerInput from '../components/RangePickerInput';
import { defaultDateFormat, defaultDateTimeFormat } from '../constants';
import DatePickerProvider from '../DatePickerProvider';
import { allFilters } from '../filters';
import { DateRangeFilter, Filter, Filters } from '../types';
import { formatInputValue, isSelectedFilterPreset } from '../utils';

export type RangePickerProps = {
  initialSelectedDay?: DateRange;
  inputValue?: (date: DateRange | undefined) => string;
  onChange: (date: DateRange, preset?: DateRangeFilter) => void;
  format?: string;
  filterPresets?: DateRangeFilter[];
  toDate?: Date | ((dateRange?: DateRange) => Date | undefined);
  fromDate?: Date | ((dateRange?: DateRange) => Date | undefined);
  maxNumberOfDays?: number;
  inputSx?: SxProps;
  triggerInputProps?: DatePickerContainerProps['inputProps'];
  selectedRange?: DateRange;
  allowTimeInput?: boolean;
  testId?: string;
  feedbackComponent?: ReactNode | ((dateRange?: DateRange) => ReactNode);
};

const RangePicker = ({
  initialSelectedDay,
  inputValue,
  onChange,
  allowTimeInput,
  format: _format = allowTimeInput ? defaultDateTimeFormat : defaultDateFormat,
  filterPresets,
  toDate,
  fromDate,
  maxNumberOfDays,
  triggerInputProps,
  selectedRange: selectedRangeProp,
  testId,
  feedbackComponent,
}: RangePickerProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null);
  const [selectedRange, setSelectedRange] = useState<DateRange | undefined>(
    initialSelectedDay ??
      selectedRangeProp ?? {
        from: startOfDay(new Date()),
        to: sub(endOfDay(new Date()), { seconds: 1 }),
      }
  );
  const selectedRangeCopy = useRef<DateRange | undefined>();
  const selectedFilterPresetCopy = useRef<DateRangeFilter | undefined>();
  const [month, setMonth] = useState<Date | undefined>(selectedRange?.from);

  const validatedFilters: Partial<Filters> = useMemo(() => {
    const validKeys = Object.keys(allFilters).filter((key) =>
      filterPresets?.includes(key as keyof typeof allFilters)
    );
    return pick(allFilters, validKeys);
  }, [filterPresets]);

  const [selectedFilterPreset, setSelectedFilterPreset] = useState<
    DateRangeFilter | undefined
  >(undefined);

  const handleOpen = (event: React.MouseEvent<HTMLInputElement>) => {
    selectedRangeCopy.current = selectedRange;
    selectedFilterPresetCopy.current = selectedFilterPreset;
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    if (selectedRange && selectedRangeCopy.current) {
      const isEqualFrom =
        selectedRange.from &&
        selectedRangeCopy.current.from &&
        isEqual(selectedRange.from, selectedRangeCopy.current.from);
      const isEqualTo =
        selectedRange.to &&
        selectedRangeCopy.current.to &&
        isEqual(selectedRange.to, selectedRangeCopy.current.to);
      const rangeIsEqual = isEqualFrom && isEqualTo;
      if (!rangeIsEqual) {
        setSelectedRange(selectedRangeCopy.current);
        setSelectedFilterPreset(selectedFilterPresetCopy.current);
      }
    }
    setAnchorEl(null);
  };

  const handleApply = () => {
    if (selectedRange) {
      selectedRangeCopy.current = selectedRange;
      selectedFilterPresetCopy.current = selectedFilterPreset;
      onChange(selectedRange, selectedFilterPreset);
      handleClose();
    }
  };

  const defaultInputValue = (range?: DateRange, preset?: DateRangeFilter) => {
    if (preset) {
      const filter = validatedFilters[preset]?.date();

      if (filter?.from && filter?.to) {
        return formatInputValue(filter.from, filter.to, _format);
      }
    }

    if (range?.from && range?.to) {
      return formatInputValue(range.from, range.to, _format);
    }

    return '';
  };

  const inputValueFormatter = inputValue
    ? inputValue.bind(null, selectedRange)
    : defaultInputValue.bind(null, selectedRange, selectedFilterPreset);

  const notEmittedValueFormatter = () => {
    if (selectedFilterPreset) {
      return allFilters[selectedFilterPreset].label;
    }
    if (!selectedRange?.from || !selectedRange?.to) {
      return '-';
    }
    const from = selectedRange.from;
    const to = selectedRange.to;

    return `${format(from, _format)} - ${format(to, _format)}`;
  };

  useEffect(() => {
    const presetExists = Object.values(validatedFilters).some(
      (filter: Filter) => isSelectedFilterPreset(selectedRange, filter)
    );
    if (!presetExists) {
      setSelectedFilterPreset(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRange, JSON.stringify(validatedFilters)]);

  useEffect(() => {
    if (!selectedRangeProp?.from || !selectedRangeProp?.to) {
      return;
    }
    setSelectedRange(selectedRangeProp);
  }, [selectedRangeProp]);

  return (
    <DatePickerProvider
      mode="range"
      format={_format}
      inputValue={inputValueFormatter}
      selected={selectedRange}
      setSelected={setSelectedRange}
      popoverAnchor={anchorEl}
      setPopoverAnchor={setAnchorEl}
      handleOpen={handleOpen}
      handleClose={handleClose}
      handleApply={handleApply}
      filterPresets={filterPresets}
      selectedFilterPreset={selectedFilterPreset}
      setSelectedFilterPreset={setSelectedFilterPreset}
      toDate={toDate}
      fromDate={fromDate}
      maxNumberOfDays={maxNumberOfDays}
      allowTimeInput={allowTimeInput}
    >
      <DatePickerContainer
        aside={
          <Box
            display="flex"
            flexDirection="column"
            minWidth="133px"
            padding="16px"
            borderRight={(theme) => `1px solid ${theme.palette.grey[100]}`}
          >
            <FilterPresetsList filters={validatedFilters} setMonth={setMonth} />
          </Box>
        }
        inputProps={triggerInputProps}
        testId={testId}
      >
        <RangePickerInput setMonth={setMonth} />

        <DayPickerWrapper
          month={month}
          defaultMonth={month}
          onMonthChange={() => setMonth(undefined)}
        />

        {feedbackComponent && typeof feedbackComponent === 'function'
          ? feedbackComponent(selectedRange)
          : feedbackComponent}

        <DatePickerFooter
          hint={
            <Box gap="4px" display="flex">
              {selectedRange?.from && selectedRange?.to && (
                <>
                  <Typography variant="P12M" color="grey.500">
                    Selected range:
                  </Typography>
                  <Typography variant="P12R" color="grey.400">
                    {notEmittedValueFormatter()}
                  </Typography>
                </>
              )}
            </Box>
          }
        />
      </DatePickerContainer>
    </DatePickerProvider>
  );
};

export default RangePicker;
