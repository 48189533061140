import { ReactNode, useCallback, useMemo, useState } from 'react';

import { styled } from '@mui/material';
import { SxProps } from '@mui/system';
import SwipeableViewsContainer, {
  SwipeableViewsProps,
} from 'react-swipeable-views';

import { ConditionalWrapper, mergeSx } from '@cast/design-system';

import { SwipeableView } from './_components';
import { SwipeableViewContext, SwipeableViewsContext } from './context';
import { SwipeableViewsState } from './types';

const StyledSwipeableViewsContainer = styled(SwipeableViewsContainer)``;

type SlideConstructor = (
  state: SwipeableViewsState & { isActive: boolean }
) => ReactNode;

type Props<C extends Record<string, ReactNode | SlideConstructor>> = Omit<
  SwipeableViewsProps,
  'children'
> & {
  slides: C;
  activeSlide?: keyof C;
  onSlideChange?: (slide: keyof C) => void;
  initialSlide?: C;
  sx?: SxProps;
  slidesWrapperSx?: SxProps;
  slideWrapperSx?: SxProps;
  contentBefore?: ReactNode | ((state: SwipeableViewsState) => ReactNode);
  contentAfter?: ReactNode | ((state: SwipeableViewsState) => ReactNode);
  disableAnimations?: boolean;
};
export const SwipeableViews = <
  C extends Record<string, ReactNode | SlideConstructor>
>({
  slides: _slides,
  activeSlide,
  sx,
  slidesWrapperSx,
  slideWrapperSx,
  initialSlide,
  onSlideChange,
  contentBefore = null,
  contentAfter = null,
  disableAnimations,
  animateHeight = true,
  ...props
}: Props<C>) => {
  const slides = Object.entries(_slides);
  const [actionsRef, setActionsRef] = useState<{ updateHeight?: () => void }>(
    {}
  );
  const isControlled = !!activeSlide;
  const [_activeSlide, _setActiveSlide] = useState<keyof C>(() =>
    !isControlled ? initialSlide || (slides[0][0] as any) : activeSlide
  );
  const currentSlide: keyof C = isControlled ? activeSlide : _activeSlide;
  const currentSlideIndex = slides.findIndex(([key]) => key === currentSlide);

  const setSlide = useCallback(
    (slide: keyof C) => {
      if (isControlled) {
        onSlideChange?.(slide);
      } else {
        _setActiveSlide(slide);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isControlled]
  );

  const prevSlideIndex = currentSlideIndex - 1;
  const nextSlideIndex = currentSlideIndex + 1;

  const state: SwipeableViewsState = useMemo(() => {
    const hasPreviousSlide = !!slides[prevSlideIndex];
    const hasNextSlide = !!slides[nextSlideIndex];
    return {
      updateHeight: actionsRef.updateHeight,
      goToFirstSlide: () => {
        if (currentSlideIndex) {
          setSlide(slides[0][0]);
        }
      },
      hasPreviousSlide,
      goToPreviousSlide: () => {
        if (hasPreviousSlide) {
          setSlide(slides[prevSlideIndex][0]);
        }
      },
      hasNextSlide,
      goToNextSlide: () => {
        if (hasNextSlide) {
          setSlide(slides[nextSlideIndex][0]);
        }
      },
      goToLastSlide: () => {
        if (currentSlideIndex !== slides.length - 1) {
          const lastSlide = slides.at(-1)?.[0];
          if (lastSlide) {
            setSlide(lastSlide);
          }
        }
      },
      setSlide,
      currentSlide: currentSlide as string,
      currentSlideIndex,
    };
  }, [
    slides,
    prevSlideIndex,
    nextSlideIndex,
    actionsRef.updateHeight,
    setSlide,
    currentSlide,
    currentSlideIndex,
  ]);

  return (
    <SwipeableViewsContext.Provider value={state}>
      <>
        {typeof contentBefore === 'function'
          ? contentBefore(state)
          : contentBefore}
      </>

      <StyledSwipeableViewsContainer
        action={setActionsRef}
        index={currentSlideIndex}
        animateHeight={animateHeight}
        sx={mergeSx(
          sx,
          slidesWrapperSx && {
            '& .react-swipeable-view-container': slidesWrapperSx,
          },
          slideWrapperSx && {
            '& .react-swipeable-view-container > div': slideWrapperSx,
          }
        )}
        springConfig={
          disableAnimations
            ? {
                duration: '0s',
                easeFunction: 'cubic-bezier(0.1, 0.35, 0.2, 1)',
                delay: '0s',
              }
            : undefined
        }
        data-activeSlide={activeSlide}
        {...(props as any)}
      >
        {slides.map(([key, value]) => (
          <SwipeableViewContext.Provider
            key={key}
            value={{ key, isActive: key === currentSlide }}
          >
            <ConditionalWrapper
              condition={animateHeight}
              wrapper={(child) => <SwipeableView>{child}</SwipeableView>}
            >
              {typeof value === 'function'
                ? value({ ...state, isActive: key === currentSlide })
                : value}
            </ConditionalWrapper>
          </SwipeableViewContext.Provider>
        ))}
      </StyledSwipeableViewsContainer>
      <>
        {typeof contentAfter === 'function'
          ? contentAfter(state)
          : contentAfter}
      </>
    </SwipeableViewsContext.Provider>
  );
};
